import React, { useEffect, useState } from 'react'
import NavBar from './NavBar'
import './css/RegisterCompany.css'
import { MdContactMail } from "react-icons/md";
import { FcAbout, FcMindMap, FcOrganization, FcPhoneAndroid} from "react-icons/fc";
import PhoneNav from './PhoneNav';
import { useFormik } from 'formik'
import instance from '../Services';
import { RequestEndpoints } from '../Services/endpoints';
import {useHistory} from 'react-router-dom'
import {
  TextField,
  InputAdornment,
  Grid,
  Container,
  Paper,
  IconButton,
  Tooltip,
//   CircularProgress,
  FormControl,
  Select,
  MenuItem,

  InputLabel,
  Fab,
  makeStyles,
} from "@material-ui/core";
const  initialValues = {
        companyName: '',
        companySector: '',
        companyEmail: '',
        phone: '',
        address1: '',
        address2: '',
        companyInfo: '',
        
     }
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(2),
    },
  },
}))

function RegisterCompany() {
 const [message, setMessage] = useState('')
 const [loading, setLoading] = useState(false)
 const [open, setOpen] = useState(false)
 const [sectors, setSetors] = useState([]);
 const [formData, setFormData] = useState(initialValues)
  
  const handleChange = (e) => {
    setFormData(prevState => {
      return {
        ...prevState, [e.target.name] : e.target.value
      }
    })
  }
  const handleUpload = (e) => {
  
  }

 






    return (
        <>
        
         <div className="hide-on-small"> <NavBar/></div>
         <PhoneNav/>
         <iframe title="Register Company" 
         src="https://oxfamgrti.com/gtri/register.jsp"  style={{width: '100%', height: '150vh', border: "0"}} ></iframe> 
        
        
        
        </>
    )
}

export default RegisterCompany
