import React from 'react'
import NavBar from '../NavBar'
import PhoneNav from '../PhoneNav'
import './pillarDetailed.css'

function PillarDetailed(props) {
   console.log(props)
    const pillarName = props.location?.state?.pillar?.pilar?.name
    const description = props.location?.state?.pillar?.pilar?.description
    // const percentage = props.location?.state?.pillar?.pilar?.percentage
    const color = props.location?.state?.pillar?.pilar?.bgColor
    
    const subPillar = props.location?.state?.pillar?.pillarandparameters
   
    return (
        <div className="pillarDetailed">
           <div className="hide-on-small"> <NavBar/></div>
            <PhoneNav/>
        
           <div className="pillarDetailedContainer">
                <div className="PillarContainer">
                    <h2 className="pillarName" style={{textDecorationColor: `${color}`}}>{pillarName}</h2>
                    <p className="pillarDescription">{description}</p>

                    <h2 className="subPillarHeader">Information Sub Pillar </h2>
                  
                    {subPillar.map(sub => {
                       
                      return (
                          <div>
                              <ul>
                                  <li className="pillarList">
                                      <p className="subPillar">Sub Pillar: <strong>{sub?.pramname}</strong></p>
                                      <p className="subPillar">Pillar Percentage: <strong>{sub?.pramcent}</strong></p>
                                      <p className="subPillar">Parameter Percentage: <strong>{sub?.pillarcent}</strong></p>
                                    </li>
                                 
                                </ul>
           
                          </div>
                      )
                        
                    })}
                </div>
           </div>
    
        
        </div>
    )
}

export default PillarDetailed
