import React from 'react'
import {useHistory} from 'react-router-dom'

function Pillar({title, BgColor, icon, altName, pillar}) {
    const history = useHistory()

    return (
        <>
        {/* <Link to="/pillarDetailed/1" style={{textDecoration: 'none'}} title="click to view more"> */}
         <div className="pillar" onClick={() => history.push({pathname: '/pillarDetailed', state: {pillar:pillar}})} style={{backgroundColor: `${BgColor}`}}>
                        <div className="pillar__head">
                            <p>{title}</p>
                        </div>
                        <div className="pillar__body">
                            <div className="imgCover">
                                <img src={icon} alt={altName}/>
                            </div>
                        </div>
                    </div>
                    {/* </Link> */}
        </>
    )
}

export default Pillar
