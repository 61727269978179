import React from 'react'
import { Link } from 'react-router-dom'
import './css/ShowCase.css'

import Chart from './Chart'
import {useTranslation} from 'react-i18next'

const AfricaMap = process.env.PUBLIC_URL + '/africa-map-dotted-lines.png'
// const RankingSvg = process.env.PUBLIC_URL + '/rankings.png'
function ShowCase() {
    const { t } = useTranslation()

    return (
        <div className="showcase">
            <div className="container flex">
                <div className="sec1">
                    <h1 className="showcase__title ">{t('GTRI')}</h1>
                    <p className="showcase__desciription"> {t('gtri description')} </p>
                    <p>{t('gtri sub description')}</p>
                    <div style={{marginTop: '30px'}}>
                        <Link to="/report" className="cus_button showcase__button">{t('view report')}</Link>
                    </div>
                </div>
                <div className="sec2" style={{background:  `url('${AfricaMap}') no-repeat center center/cover`}}>
                    {/* <img src={RankingSvg} alt="svg-ranking"/> */}
                    <Chart/>
                </div>
            </div>
        </div>
    )
}

export default ShowCase
