import React from 'react'
import NavBar from '../NavBar'
import '../css/AboutUs.css'
import AboutShowCase from './About/AboutShowCase'
import SectionThree from '../SectionThree'
import PhoneNav from '../PhoneNav'

function AboutUs() {
    return (
        <>
           <div className="hide-on-small"> <NavBar/></div>
            <PhoneNav/>
            <div className="about__page">
                <AboutShowCase/>
                {/* <MeetOurTeam/> */}
                <div className="container">
                    <h1 className="gtri__classificationTitle fnTitle">CATEGORISATION OF THE PARTICIPANTS OF GRTI TOOLKIT</h1>
                    <p className="gtri__classificationDescrip">The underlining principle/understanding of the GRTI is that, all participants who should be included in the ranking should be vetted and considered to a certain degree to be responsible taxpayers. The participants will be graded into four main categories based on their scores; Platinum, Gold, Silver and Bronze, with platinum being those who have exhibited the highest standard of responsible taxpayer behaviours.</p>

                    <h2 className="gtri__classificationTableTitle">Our categories are scored</h2>

                    <div className="score-table">
                 <table>
  <tr className="tableHeader1">
    <th>CATEGORY</th>
    <th colSpan="2">SCORE RANGE</th>
   
  </tr>
  <tr className="tableHeader2">
    <th></th>
    <th>From</th>
    <th>To</th>
  </tr>
  <tr>
       
    <td>Platinum</td>
    <td>85% </td>
    <td>100%</td>
  </tr>
  <tr>
    <td>Gold </td>
    <td>65% </td>
    <td>74%</td>
  </tr>
  <tr>
    <td>Silver </td>
    <td>50% </td>
    <td>64%</td>
  </tr>
  <tr>
    <td>Bronze </td>
    <td>Below 50%</td>
    <td></td>
  </tr>

</table>
                    </div>
                </div>
            </div>
     <SectionThree/>
        
        </>
    )
}

export default AboutUs
