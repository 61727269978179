

export const RequestEndpoints = {
    REGISTER_COMPANY: "/gtri/regc",
    SEND_MESSAGE: "/gtri/addmsg",
    SEND_SUBSCRIPTION: "/gtri/msgsend",
    GET_ALL_COMPANIES: "/gtri/grtidata?from=2023&to=2023",
    GET_SECTORS: "/gtri/sec",
    GET_PILLARS: "/gtri/pillars",
    GET_PILLAR_IMAGE: "https://oxfamgrti.com/gtri/pillarpic?id="
}