import React, { useState } from 'react'
import { FcAbout, FcBusinessman, FcDocument, FcPhoneAndroid, FcSmartphoneTablet, FcSms } from 'react-icons/fc'
import NavBar from '../NavBar'
import '../css/Contact.css'
import PhoneNav from '../PhoneNav'
import instance from '../../Services'
import { RequestEndpoints } from '../../Services/endpoints'
import {useHistory} from 'react-router-dom'

let initialFormData = {email: '', firstname: '', lastname: '', phone: '', subject:'', message: ''}
function Contact() {
    const [formData, setFormData] = useState(initialFormData)
    const history = useHistory()

    const onChangeValue = (e) => {
        setFormData(prevState => {
            return {
                ...prevState, [e.target.name] : e.target.value
            }
        })
    }
    const sendMessage = async () => {
        if(formData.email === '' || formData.lastname === '' || formData.firstname === '' || formData.phone === '' || formData.subject === '' || formData.message === '' ){
            alert('Please make sure to fill the fields')
        }
        // console.log(formData)
        //TODO:// Send the data to the server
        const response = await instance.post(RequestEndpoints.SEND_MESSAGE, formData)
        // {
        //     emailAddress: formData.email,
        //     firstname: formData.firstname,
        //     lastname: formData.lastname,
        //     phone: formData.phone,
        //     subject: formData.subject,
        //     message: formData.message
        // }
        if(!response.errorMessage){
            history.push('/?message-sent')
        }else{
            console.log('error while sending message')
        }
    }
    return (
        <>
         <div className="hide-on-small"> <NavBar/></div>
         <PhoneNav/>
            <div className="conatact-page">
                <div className="container">
                    <div className="grid__container">
                        <div className="companyInfo" style={{background:"url('oxfam-img.jpg') no-repeat center center/cover"}}>
                            <div className="overlay">
                                <div className="items">
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Office address: </p>
                                            <p>Oxfam International Headquarters The Atrium Chaka Road Kilimani, Nairobi, Kenya</p>
                                        </div>
                                    </div>
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Contact us through: </p>
                                            <p>+254 (0) 20 2820000</p>
                                            <p>+254 722 200417</p>
                                        </div>
                                    </div>
                                    <div className="locItems">
                                        <div className="icon"><FcSmartphoneTablet/></div>
                                        <div>
                                            <p className="location__header">Contact Mail: </p>
                                            <p>info@oxfamgrti.org</p>
                                            <p>oxfamgrti@gamail.org</p>
                                           
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="form__message">
                            <div className="form">
                                <h2 className="title fnTitle">Send Us Your Message</h2>
                                <div className="form-data">
                        <div className="icon__input">
                            <div className="icon">
                                <FcSms/>
                                <p>Email Address <span>*</span></p>
                                </div>
                            <input placeholder="Email Adress" name="email" value={formData.email} onChange={(e) => onChangeValue(e)}/>
                        </div>
                        
                        <div className="grid">
                             <div className="icon__input">
                            <div className="icon">
                                <FcBusinessman/>
                                  <p>FirstName <span>*</span></p>
                                </div>
                            <input placeholder="FirstName" name="firstname" value={formData.firstname} onChange={(e) => onChangeValue(e)}/>
                          </div>
                             <div className="icon__input">
                            <div className="icon">
                                <FcBusinessman/>
                                  <p>LastName <span>*</span></p>
                                </div>
                            <input placeholder="LastName" name="lastname" value={formData.lastname} onChange={(e) => onChangeValue(e)}/>
                          </div>
                        </div>
                         <div className="icon__input">
                            <div className="icon">
                                <FcPhoneAndroid/>
                                 <p>Phone Number </p>
                                </div>
                            <input placeholder="Phone Number" name="phone" value={formData.phone} onChange={(e) => onChangeValue(e)}/>
                        </div>
                        <div className="icon__input">
                            <div className="icon">
                                <FcAbout/>
                                 <p>Subject <span>*</span></p>
                            </div>
                            <input placeholder="Subject/Title" name="subject" value={formData.subject} onChange={(e) => onChangeValue(e)}/>
                        </div>
                       
                        <div className="message">
                             <div className="icon">
                                <FcDocument/>
                                 <p>Message <span>*</span></p>
                            </div>
                            <textarea  rows="5" cols="5" name="message" placeholder="Start typing your message here" value={formData.message} onChange={(e) => onChangeValue(e)}>

                            </textarea>
                        </div>
         
                        <div className="button">
                            <button onClick={sendMessage} className="cus_button">Send Message</button>
                        </div>
                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Contact
