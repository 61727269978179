import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import './css/Footer.css'
import {FaFacebookF, FaLinkedinIn} from 'react-icons/fa'
import {ImYoutube2} from 'react-icons/im'
import {AiOutlineTwitter} from 'react-icons/ai'
import {GrInstagram} from 'react-icons/gr'
import {useHistory} from 'react-router-dom'
import instance from '../Services'
import { RequestEndpoints } from '../Services/endpoints'
// import { toast } from 'react-toastify'
function Footer() {
    const history = useHistory()
    const [email, setEmail] = useState('')
    
    function validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

  const sendSubscription = async(e) =>{
      e.preventDefault()

        if(email === "" ){
            // toast.error('Email cannot not be empty.', {position: toast.POSITION.TOP_RIGHT})
            return
        }else if(validateEmail(email) === false){
            // toast.error('Please provide a valid email address')
            return
        }
       const response =  await instance.get(RequestEndpoints.SEND_SUBSCRIPTION, {email})
        if(response.status === 200){
            if(response?.data?.message === "Successful"){
                setEmail("")
                history.push('/?subscription-sent')
                // toast('Thank you for subscribing you will recieve updates from us.')
            }
        }else{
            // toast.error('Opps error occur please try again later')
        }
    }

    return (
        <div className="footer" style={{background: "url('/images/map.png') no-repeat center center/cover"}}>
            <div className="overlay">
            <div className="container">
                <div className="items">
                        <div className="subscribe__side">
                        <h3 className="menu__title__header">Why Oxfam?</h3>
                                <p className="decription">Oxfam works with others to overcome poverty and suffering Oxfam GB is a member of Oxfam International and a company limited by guarantee registered in England No. 612172. Registered office: Oxfam House, John Smith Drive, Cowley, Oxford, OX4 2JY. A registered charity in England and Wales (no 202918) and Scotland (SC 039042)</p>
                            <div className="form">
                                <h2 className="title">Subscribe Here</h2>
                                <form>
                                <input type="email" required value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email" className="form-input"/>
                                <button className="button" onClick={(e) => sendSubscription(e)}>Subscribe</button>
                                </form>
                            </div>
                         
                            
                        </div>
                    <div className="links__side">
                        <div>
                            <h3 className="menu__title__header">Home Links</h3>
                            <ul>
                                <li><Link to="/">Home</Link></li>
                               
                                <li><Link to="/about">About Us</Link></li>
                                <li><Link to="/contact">Contact Us</Link></li>
                                <li><Link to="/register">Register Company</Link></li>
                            </ul>
                        </div>
                        <div>
                            <h3 className="menu__title__header">Other Links</h3>
                            <ul>
                                <li><Link to="/report">GRTI ranking</Link></li>
                                <li><Link to="/companies">Top Ranked Companies</Link></li>
                                <li><Link onClick={() => window.open('https://www.oxfam.org/en/what-we-do/countries/ghana')}>Oxfam Main Website</Link></li>
                                <li><Link to="/register">Register Company</Link></li>
                                {/* <li><Link>Home</Link></li> */}
                            </ul>
                        </div>
                        <div>
                            <h3 className="menu__title__header">Locate Us</h3>
                            <p style={{marginBottom: '5px', marginTop: '5px', fontWeight:'bold'}}>Office Address</p>
                            <p>435/12/155 Nii Aku-Ashong Street, Off Gifty Homes, Adjiringanor - Accra.</p>
                            <p style={{marginBottom: '5px', marginTop: '5px', fontWeight:'bold'}}>Call Us</p>
                           
                            <p>+233 (0)320 901373</p>
                            <p>+233 (0)320 901374</p>
                                <p style={{marginBottom: '5px', marginTop: '5px', fontWeight:'bold'}}>Social Media</p>
                            <div className="social__icons">
                               <div className="iconContainer"> <FaFacebookF className="icon hoverStyle" onClick={()=> window.open('https://www.facebook.com/OxfamInGhana/', '_blank')}/></div>
                               <div className="iconContainer"> <FaLinkedinIn className="icon hoverStyle" onClick={() => window.open('https://www.linkedin.com/in/oxfam-ghana-6380211a7?originalSubdomain=gh', '_blank')}/></div>
                               <div className="iconContainer"> <ImYoutube2 className="icon hoverStyle" onClick={() => window.open('https://www.youtube.com/watch?v=-RTzIsfbza0', '_blank')}/></div>
                               <div className="iconContainer"> <AiOutlineTwitter className="icon hoverStyle" onClick={() => window.open('https://twitter.com/oxfaminghana?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor', '_blank')}/></div>
                               <div className="iconContainer"> <GrInstagram className="icon hoverStyle" onClick={() => window.open('', '_blank')}/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
    )
}

export default Footer
